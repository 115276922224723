<ng-container *ngIf="showSpinner">
  <div class="item p-3">
    <ngx-skeleton-loader [theme]="{height: '40vh'}" count="1" ></ngx-skeleton-loader>
    <div>
      <div style="display: grid; justify-content: center; grid-template-columns: repeat(1, 1fr); column-gap: 10px">
        <ngx-skeleton-loader [theme]="{height: '5vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '5vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '5vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '5vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader [theme]="{height: '11vh'}" count="1" ></ngx-skeleton-loader>
      <div style="display: grid; justify-content: center; grid-template-columns: repeat(4, 1fr); column-gap: 10px">
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!showSpinner">
  <app-app-header></app-app-header>
  <div style="background: #3f51b524" class="destination-details">
    <div class="destination-tile">
      <img (load)="someEvent()" [src]="destinationBanner" alt="Image" style="display: none;">
      <ng-container *ngIf="bannerLoaded; else placeHolder">
        <div [style.background]="'url(' + destinationBanner + ') center/cover no-repeat'" class="image-container">
          <div class="content-tile">
            <div class="title">
              {{destinationDetails?.name}}
            </div>
            <div class="description">
              {{destinationDetails?.description}}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #placeHolder>
        <ngx-skeleton-loader style="width: 100%" [theme]="{width: '100%', height: '50vh', 'border-radius': '20px'}" count="1" ></ngx-skeleton-loader>
      </ng-template>
    </div>

    <div class="abstract-image-gallery">
      <div class="lg:mt-8 sm:mt-4 md:mt-4 mt-5 lg:mb-6 sm:mb-4 md:mb-4 mb-6">
        <app-section-header [showMore]="false"
                            [mainTitle]="'Explore the Must-See Attractions'"
                            [subTitle]="'Top Selling'">
        </app-section-header>
      </div>
      <div class="image-container">
        <!-- First column with 1 image -->
        <div class="gallery-column">
          <img class="gallery-image left-one" [src]="featuredImages[0]?.img" alt="Image 1">
        </div>

        <!-- Second column with 2 rows of images -->
        <div class="gallery-column">
          <img class="gallery-image middle-top" [src]="featuredImages[1]?.img" alt="Image 2">
          <img class="gallery-image middle-bottom" [src]="featuredImages[2]?.img" alt="Image 3">
        </div>

        <!-- Third column with 2 rows of images -->
        <div class="gallery-column">
          <img class="gallery-image right-top" [src]="featuredImages[3]?.img" alt="Image 4">
          <img class="gallery-image right-bottom" [src]="featuredImages[4]?.img" alt="Image 5">
        </div>
      </div>
    </div>

    <div *ngIf="camping && camping?.length > 0">
      <div class="lg:mt-8 sm:mt-4 md:mt-4 mt-5 lg:mb-6 sm:mb-4 md:mb-4 mb-6">
        <app-section-header [showMore]="false"
                            [mainTitle]="'Explore Best Camping Destinations'"
                            [subTitle]="'Camping'">
        </app-section-header>
      </div>

      <app-listing-ui-card [listing]="camping"></app-listing-ui-card>
    </div>

    <div *ngIf="similarDestinations && similarDestinations.length">
      <div class="lg:mt-8 sm:mt-4 md:mt-4 mt-5 lg:mb-6 sm:mb-4 md:mb-4 mb-6">
        <app-section-header [showMore]="false"
                            [mainTitle]="'Uncover Destinations Like This'"
                            [subTitle]="'Embark'">
        </app-section-header>
      </div>
      <app-image-tiles [tilesData]="similarDestinations"></app-image-tiles>
    </div>
    <div>
      <div class="lg:mt-8 sm:mt-4 md:mt-4 mt-5 lg:mb-6 sm:mb-4 md:mb-4 mb-6">
        <app-section-header [showMore]="false"
                            [mainTitle]="'Journey Experiences'"
                            [subTitle]="'testimonials'">
        </app-section-header>
      </div>
      <app-testimonial-cards></app-testimonial-cards>
    </div>

  </div>
  <app-footer></app-footer>
</ng-container>
