<div class="dashboard-container p-3">
  <div class="pb-3">
    <mat-card>
      <div class="d-flex justify-content-between p-3">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <button (click)="goGridPage()" mat-mini-fab color="primary"
                    aria-label="Example icon button with a menu icon">
              <i class="fa fa-chevron-left"></i>
            </button>
          </div>
          <div class="ml-1 d-flex align-items-center">
            <div style="font-size: 18px; font-weight: bold">{{this.customPricingId ? 'Update' : 'Add'}} Custom Booking
            </div>
          </div>
        </div>
        <div>
          <button (click)="userAction()" class="color-white" mat-raised-button
                  color="accent">{{this.customPricingId ? 'Update' : 'Add'}} Custom Booking
          </button>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="pb-3">
    <mat-card>
      <div class="component-container p-5">
        <div class="container">
          <form [formGroup]="componentForm">
            <div class="row">
              <div class="col-md-12 col-lg-4 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name" type="text" placeholder="Enter Name">
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-4 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email" type="text" placeholder="Enter Email">
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-4 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Phone Number</mat-label>
                  <input matInput formControlName="contact_number" type="number" placeholder="Enter Phone Number">
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-4 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Accommodation</mat-label>
                  <mat-select tabindex="2" formControlName="accommodation_id">
                    <mat-option *ngFor="let acco of accommodations"
                                [value]="acco.id">{{ acco.name }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="componentForm.get('accommodation_id')?.invalid && componentForm.get('accommodation_id')?.touched">
                    Please select Accommodation
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-4 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Camping & Destination</mat-label>
                  <input matInput formControlName="camping" type="text" placeholder="Enter Camping">
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-4 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width example-form-field">
                  <mat-label>Date Range</mat-label>
                  <mat-date-range-input
                    [rangePicker]="campaignOnePicker">
                    <input matStartDate placeholder="Start date" formControlName="start_date">
                    <input matEndDate placeholder="End date" formControlName="end_date">
                  </mat-date-range-input>
                  <mat-hint>Start Date (MM/DD/YYYY) – End Date (MM/DD/YYYY)</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                  <mat-error *ngIf="(componentForm.get('start_date')?.invalid && componentForm.get('start_date')?.touched) ||
                  (componentForm.get('end_date')?.invalid && componentForm.get('end_date')?.touched)">
                    Please select Start & End Date
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Booking Price</mat-label>
                  <input tabindex="1" #booking_price min="0" max="1000000" matInput formControlName="booking_price" type="number"
                         placeholder="Enter Price">
                  <mat-error *ngIf="componentForm.get('booking_price')?.invalid && componentForm.get('booking_price')?.touched">Please
                    enter a valid Price
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-2 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Beds</mat-label>
                  <input tabindex="1" #beds min="0" max="1000000" matInput formControlName="beds" type="number"
                         placeholder="Enter Beds">
                  <mat-error *ngIf="componentForm.get('beds')?.invalid && componentForm.get('beds')?.touched">Please
                    Booking Beds exceeding available beds
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- Add more form fields here -->
          </form>
        </div>
      </div>
    </mat-card>
  </div>
</div>
