<div class="slick-carousal-container pb-3">
  <ngx-slick-carousel class="carousel"
                      #slickModal="slick-carousel"
                      [config]="slideConfig"
                      (init)="slickInit($event)"
                      (breakpoint)="breakpoint($event)"
                      (afterChange)="afterChange($event)"
                      (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of reviews" class="slide">
      <div class="card feedback-card lg:rounded-3xl
         rounded-2xl sm:rounded-1xl overflow-hidden">
        <div class="card-body d-flex flex-column gap-2 justify-content-between">
          <p class="feedback-content card-text">{{slide?.comment}}</p>
          <div class="d-flex justify-content-center align-items-center">
            <img style="max-width: 45px" [src]="slide?.userImg" alt="">
            <div class="ms-2">
              <div class="feedback-title">{{slide?.name}}</div>
              <div class="d-flex fd-rating">
                <div>{{slide?.ratings}}</div>
                <div class="stars ms-1">
                  <div class="str" *ngFor="let star of [1,2,3,4]">
                    <i class="fa fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
