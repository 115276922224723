<div class="dashboard-container p-3">
  <div class="pb-3">
    <mat-card>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center" style="padding: 1rem">
          <div style="font-size: 16px; font-weight: bold">Camping</div>
        </div>
        <div class="d-flex align-items-center">
          <div class="search-input-box width-300 input-group">
            <input appDebounceKeyUp [debounceTime]="1000"
                   (onEvent)="handleDebouncedKeyUp($event)" type="text" class="form-control" placeholder="Search Camping">
            <div class="input-group-append">
              <button class="btn" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>

          <mat-paginator
            [pageSize]="pageSize"
            #paginator
            (page)="onPageChange($event)"
            [length]="totalLength">
          </mat-paginator>
          <div style="padding: 0 20px">
            <button (click)="onAddCamping()" class="color-white" mat-raised-button color="accent"> Add Camping &nbsp; <mat-icon>add</mat-icon></button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="pb-3">
    <mat-card>
      <div class="overflow-scroll" *ngIf="dataSource && dataSource.length > 0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Camping </th>
            <td mat-cell *matCellDef="let element">
              <div style="min-width: 200px;" class="p-3 d-flex align-items-center flex-column">
                <div class="full-width">
                  <img style="object-fit: cover; max-height: 100px; box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); width: 100%" [src]="element.profile_image_url" alt="">
                </div>
                <div class="full-width"
                     style="box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); background: var(--primary-color);padding: 10px;color: white;text-align: center;border-bottom-left-radius: 15px;">
                  {{element.name}}, <br>
                  <div style="color: #808080">
                    {{element?.destinationName}}
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="mapLink">
            <th mat-header-cell *matHeaderCellDef> Google Map </th>
            <td mat-cell *matCellDef="let element">
              <div [innerHTML]="element?.mapLink" class="truncate-text camping-map">
              </div>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let element">
              <div style="min-width: 350px; padding: 15px" class="truncate-text">
                {{element?.address}}
              </div>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="gallery">
            <th mat-header-cell *matHeaderCellDef> Image Gallery </th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex">
                <ng-container *ngFor="let image of element.images">
                  <img class="single-gallery-image" src="{{image?.url}}" alt="">
                </ng-container>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action" stickyEnd>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td style="width: 60px" [matMenuTriggerData]="element" [matMenuTriggerFor]="userFeedbackMenu" #menuTrigger mat-cell *matCellDef="let element">
              <div style="cursor: pointer; text-align: center"><mat-icon>more_vert</mat-icon></div>
            </td>
          </ng-container>

          <mat-menu #userFeedbackMenu="matMenu">
            <ng-template matMenuContent let-status="status" let-id="id">
              <button (click)="onEditUser(id)" mat-menu-item>Edit
                <mat-icon color="primary">edit</mat-icon>
              </button>
              <button (click)="onDeleteUser(id)" mat-menu-item>Delete
                <mat-icon color="primary">delete</mat-icon>
              </button>
            </ng-template>
          </mat-menu>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div class="no-data" *ngIf="dataSource.length === 0">
        No Data Available
      </div>
    </mat-card>
  </div>
</div>
