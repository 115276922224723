<div class="dashboard-container p-3">
  <div class="pb-3">
    <mat-card>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center" style="padding: 1rem">
          <div style="font-size: 16px; font-weight: bold">Assets</div>
        </div>
        <div class="d-flex align-items-center">
          <div class="search-input-box width-300 input-group">
            <input appDebounceKeyUp [debounceTime]="1000"
                   (onEvent)="handleDebouncedKeyUp($event)" type="text" class="form-control"
                   placeholder="Search Camping">
            <div class="input-group-append">
              <button class="btn" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>

          <mat-paginator
            [pageSize]="pageSize"
            #paginator
            (page)="onPageChange($event)"
            [length]="totalLength">
          </mat-paginator>
          <div style="padding: 0 20px">
            <button (click)="onAddAsset()" class="color-white" mat-raised-button color="accent"> Add Camping &nbsp;
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="pb-3">
    <mat-card>
      <div *ngIf="dataSource && dataSource.length > 0" class="col-lg-12 row p-4">
        <ng-container *ngFor="let singleImage of dataSource; let i = index">
          <div style="margin-bottom: 50px; position: relative"
               class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
            <div class="avatar-upload">
              <div class="avatar-edit">
                <input type='file' [id]="'image-' + i"
                       accept=".png, .jpg, .jpeg" #fileInput
                       (change)="onChangeImage($event, singleImage)"/>
              </div>
              <div class="avatar-preview h-100">
                <label [for]="'image-' + i"
                       [ngClass]="['custom-label', 'upload-image']">
                  <i class="fa fa-pen"></i>
                </label>
                <div class="d-flex justify-content-center h-100 ">
                    <img class="w-100 object-fit-contain p-3"
                         [src]="singleImage?.image_url"
                         alt="Business Image">
                </div>
              </div>
              <div class="avatar-remove">
                <label (click)="onDeleteUser(singleImage.id)"
                       [ngClass]="['custom-label', 'remove-image']">
                  <i class="fa fa-times"></i>
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="no-data" *ngIf="dataSource.length === 0">
        No Data Available
      </div>
    </mat-card>
  </div>
</div>
