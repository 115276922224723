<div class="dashboard-container p-3">
  <div class="pb-3">
    <mat-card>
      <div class="d-flex justify-content-between p-3">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <button (click)="goGridPage()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
              <i class="fa fa-chevron-left"></i>
            </button>
          </div>
          <div class="ml-1 d-flex align-items-center">
            <div style="font-size: 18px; font-weight: bold">{{this.accommodationId ? 'Update' : 'Add'}} Accommodation</div>
          </div>
        </div>
        <div>
          <button (click)="userAction()" class="color-white" mat-raised-button color="accent">{{this.accommodationId ? 'Update' : 'Add'}} Accommodation</button>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="pb-3">
    <mat-card>
      <div class="component-container p-5">
        <div class="container">
          <form [formGroup]="componentForm" (ngSubmit)="userAction()">
            <div class="row">
              <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Name</mat-label>
                  <input tabindex="1" #name maxlength="40" matInput formControlName="name" type="text" placeholder="Enter Camping Name">
                  <mat-hint align="end">{{name.value.length}} / 40</mat-hint>
                  <mat-error *ngIf="componentForm.get('name')?.invalid && componentForm.get('name')?.touched">Please enter a valid email</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-4 col-sm-12 col-xs-12 mb-2">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Camping</mat-label>
                  <mat-select tabindex="2" formControlName="camping">
                    <mat-option *ngFor="let camp of camping"
                                [value]="camp.id">{{ camp.name}}, {{camp?.destination?.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-2 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Beds Available</mat-label>
                  <input tabindex="3" min="0" #beds max="100" matInput formControlName="beds" type="number" placeholder="Enter Beds">
                  <mat-error *ngIf="componentForm.get('beds')?.invalid && componentForm.get('beds')?.touched">Please enter a valid email</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Amenities</mat-label>
                  <mat-select tabindex="2" formControlName="amenities" multiple>
                    <mat-option *ngFor="let amenity of amenities"
                                [value]="amenity.id">{{ amenity.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Price</mat-label>
                  <input tabindex="1" #price min="0" max="1000000" matInput formControlName="price" type="number" placeholder="Enter Price">
                  <mat-error *ngIf="componentForm.get('price')?.invalid && componentForm.get('price')?.touched">Please enter a valid Price</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Discount Price</mat-label>
                  <input tabindex="1" #discount_price min="0" max="1000000" matInput formControlName="discount_price" type="number" placeholder="Enter Discount">
                  <mat-error *ngIf="componentForm.get('discount_price')?.invalid && componentForm.get('discount_price')?.touched">Please enter a valid Discount</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Weekend Price</mat-label>
                  <input tabindex="1" #weekend_price min="0" max="1000000" matInput formControlName="weekend_price" type="number" placeholder="Enter Weekend Price">
                  <mat-error *ngIf="componentForm.get('weekend_price')?.invalid && componentForm.get('weekend_price')?.touched">Please enter a valid Price</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-3 col-sm-12 col-xs-12 mb-3">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Weekend Discount Price</mat-label>
                  <input tabindex="1" #weekend_discount_price min="0" max="1000000" matInput formControlName="weekend_discount_price" type="number" placeholder="Enter Weekend Discount">
                  <mat-error *ngIf="componentForm.get('weekend_discount_price')?.invalid && componentForm.get('weekend_discount_price')?.touched">Please enter a valid Discount</mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-12">
                <div class="mb-4 image-message">
                  Please Add Camping Banner Image below.
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                <div style="position: relative; border: 1px solid #666; border-radius: 5px" class="full-width d-flex justify-content-center">

                  <ng-container>
                    <img class="destination-banner"
                         [src]="profileImageBase64?.value ?
                         profileImageBase64?.value : profileImageUrlControl.value"
                         alt="Business Image">
                  </ng-container>

                  <div style="position: absolute; top: 10px; right: 10px;">
                    <div style="position: relative">
                      <label  for="profileImage" class="edit_profile_icon">
                        <i class="fa fa-pencil"></i>
                      </label>
                      <input type='file' style="display: none" id="profileImage"
                             accept=".png, .jpg, .jpeg"
                             (change)="uploadFile($event)"/>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-lg-12">
                <div class="address-container d-flex flex-column justify-content-center align-items-center">
                  <!--                  <div  [innerHTML]="mapSrc" class="map-link-container">-->
                  <div style="margin: 20px 0; border-radius: 5px;  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)"
                       class="full-width map-link-container">
                    <div class="mapLink" [innerHTML]="mapSrc"></div>
                    <!--                    <iframe class="full-width" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.2886395929977!2d74.73017260942358!3d19.094989351304214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdcb08a3e11c9e5%3A0x644f1d50cbdbeb1c!2sSandhyadeep%20Beauty%20Parlour!5e0!3m2!1sen!2sin!4v1694535809286!5m2!1sen!2sin" width="600" height="450" style="border:0; height: 300px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>-->
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="mb-4 image-message">
                  Please Add 5 Camping Gallery Images
                </div>
              </div>
              <div class="col-lg-12 row">
                <ng-container formArrayName="galleryImages"
                              *ngFor="let singleImage of galleryImages.controls; let i = index">
                  <div style="margin-bottom: 50px; position: relative"
                       class="col-lg-4 d-flex justify-content-center align-items-center">
                    <div class="avatar-upload">
                      <div class="avatar-edit">
                        <input type='file' [id]="'imageUploadPortfolio' + i"
                               accept=".png, .jpg, .jpeg" #fileInput
                               (change)="portfolioImageAction($event, i)"/>
                      </div>
                      <div class="avatar-preview">
                        <label [for]="'imageUploadPortfolio' + i"
                               [ngClass]="['custom-label', 'upload-image']">
                          <i class="fa fa-pen"></i>
                        </label>
                        <div class="d-flex justify-content-center">
                          <ng-container *ngIf="singleImage.get('imageBase64')?.value">
                            <img style="padding: 5px; object-fit: cover; width: 100%"
                                 [src]="singleImage.get('imageBase64')?.value ?
                                                         singleImage.get('imageBase64')?.value : noImageIcon"
                                 alt="Business Image">
                          </ng-container>
                          <ng-container *ngIf="singleImage.get('imageUrlOnUI')?.value">
                            <img style="padding: 5px; object-fit: cover; width: 100%"
                                 [src]="singleImage.get('imageUrlOnUI')?.value ?
                                                         singleImage.get('imageUrlOnUI')?.value : noImageIcon"
                                 alt="Business Image">
                          </ng-container>
                          <ng-container *ngIf="!singleImage.get('imageBase64')?.value && !singleImage.get('imageUrlOnUI')?.value">
                            <img style="padding: 5px; object-fit: cover; width: 100%"
                                 [src]="noImageIcon"
                                 alt="Business Image">
                          </ng-container>
                        </div>
                      </div>
                      <!--                      <div class="avatar-remove">-->
                      <!--                        <label (click)="onRemoveConfirm(i)"-->
                      <!--                               [ngClass]="['custom-label', 'remove-image']">-->
                      <!--                          <i class="fa fa-times"></i>-->
                      <!--                        </label>-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </ng-container>
                <div style="margin-bottom: 30px; position: relative"
                     class="col-lg-4 d-flex justify-content-start align-items-center">
                  <mat-card class="image-card">
                    <input style="display: none" type='file' id="addPortFolio"
                           accept=".png, .jpg, .jpeg" #portfolioImageUpload
                           (change)="portfolioMultipleImagesAction($event)" multiple/>
                    <label for="addPortFolio" style="width: 100%; height: 100%; cursor: pointer"
                           class="d-flex justify-content-center align-items-center">
                      <i style="font-size: 45px; color: #80808054;" class="fa fa-plus"></i>
                    </label>
                  </mat-card>
                </div>
              </div>

            </div>
            <div>

            </div>
            <!-- Add more form fields here -->
          </form>
        </div>
      </div>
    </mat-card>
  </div>
</div>
