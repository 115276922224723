<div class="dashboard-container p-3">
  <div class="pb-3">
    <mat-card>
      <div class="d-flex justify-content-between p-3">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <button (click)="goUsersPage()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
              <i class="fa fa-chevron-left"></i>
            </button>
          </div>
          <div class="ml-1 d-flex align-items-center">
            <div style="font-size: 18px; font-weight: bold">{{this.userId ? 'Update' : 'Add'}} User</div>
          </div>
        </div>
        <div>
          <button (click)="userAction()" class="color-white" mat-raised-button color="accent">{{this.userId ? 'Update' : 'Add'}} User</button>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="pb-3">
    <mat-card>
      <div class="component-container p-5">
        <div class="container">
          <form [formGroup]="userForm" (ngSubmit)="userAction()">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-sm-12 col-xs-12 mb-4">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>User Name</mat-label>
                  <input tabindex="1" matInput formControlName="name" type="text" placeholder="Enter User Name">
                  <mat-error *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched">Please enter a valid email</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-lg-4 col-sm-12 col-xs-12 mb-4">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Email</mat-label>
                  <input tabindex="2" matInput formControlName="email" type="email" placeholder="Enter User email">
                  <mat-error *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched">Please enter a valid email</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-lg-4 col-sm-12 col-xs-12 mb-4">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Choose one</mat-label>
                  <mat-select formControlName="userRole">
                    <mat-option *ngFor="let role of roles" [value]="role.id">{{role?.name | titlecase}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="userForm.get('email')?.invalid">You must make a selection</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-lg-4 col-sm-12 col-xs-12 mb-4">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Password</mat-label>
                  <input maxlength="10" matInput formControlName="password" type="password" placeholder="Enter your password">
                  <mat-error *ngIf="userForm.get('password').invalid && userForm.get('password').touched">
                    Password should have special characters, number and upper case or lower case character
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-lg-4 col-sm-12 col-xs-12 mb-4">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Confirm Password</mat-label>
                  <input maxlength="10" matInput formControlName="confirmPassword" type="password" placeholder="Confirm your password">
                  <mat-error *ngIf="userForm.get('confirmPassword').invalid && userForm.get('confirmPassword').touched">
                    Please confirm your password
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Add more form fields here -->
          </form>
        </div>
      </div>
    </mat-card>
  </div>
</div>
