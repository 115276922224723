<div class="dashboard-container p-3">
  <div class="pb-3">
    <mat-card>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center" style="padding: 1rem">
          <div style="font-size: 16px; font-weight: bold">Enquiries</div>
        </div>
        <div class="d-flex align-items-center">
          <mat-paginator
            [pageSize]="pageSize"
            #paginator
            (page)="onPageChange($event)"
            [length]="totalLength">
          </mat-paginator>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="pb-3">
    <mat-card>
      <div *ngIf="dataSource && dataSource.length > 0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> User Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> User Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

            <!-- Weight Column -->
          <ng-container matColumnDef="contact_number">
            <th mat-header-cell *matHeaderCellDef> Contact Number </th>
            <td mat-cell *matCellDef="let element"> {{element.contact_number}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="lead_source">
            <th mat-header-cell *matHeaderCellDef> Source </th>
            <td mat-cell *matCellDef="let element"> {{element.lead_source}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div class="no-data" *ngIf="dataSource.length === 0">
        No Data Available
      </div>
    </mat-card>
  </div>
</div>
