<mat-drawer-container class="dashboard-sidenav-container" autosize>
  <mat-drawer #drawer class="dashboard-sidenav" mode="side">
    <div>
      <div class="logo-container p-3">
        <img [src]="sharedService.companyLogo" alt="Company Logo" class="logo-image">
        <mat-divider class="mat-divider-white"></mat-divider>
      </div>
      <div class="d-flex justify-content-center flex-column">
        <div class="pb-3 d-flex pl-1 cursor-pointer">
          <button mat-button class="nav-list" routerLinkActive="active-link" [routerLink]="['/dashboard/users']">
            <mat-icon>person</mat-icon> &nbsp; Users
          </button>
        </div>
        <div class="pb-3 d-flex pl-1 cursor-pointer">
          <button mat-button class="nav-list" routerLinkActive="active-link" [routerLink]="['/dashboard/destinations']">
            <mat-icon>location_on</mat-icon> &nbsp; Destinations
          </button>
        </div>
        <div class="pb-3 d-flex pl-1 cursor-pointer">
          <button mat-button class="nav-list" routerLinkActive="active-link" [routerLink]="['/dashboard/camping']">
            <mat-icon>nature_people</mat-icon> &nbsp; Camping</button>
        </div>
        <div class="pb-3 d-flex pl-1 cursor-pointer">
          <button mat-button class="nav-list" routerLinkActive="active-link" [routerLink]="['/dashboard/accommodation']">
            <mat-icon>hotel</mat-icon> &nbsp; Accommodation</button>
        </div>
        <div class="pb-3 d-flex pl-1 cursor-pointer">
          <button mat-button class="nav-list" routerLinkActive="active-link" [routerLink]="['/dashboard/assets-management']">
            <mat-icon>storage</mat-icon> &nbsp; Asset Management</button>
        </div>
        <div class="pb-3 d-flex pl-1 cursor-pointer">
          <button mat-button class="nav-list" routerLinkActive="active-link" [routerLink]="['/dashboard/custom-pricing']">
            <mat-icon>attach_money</mat-icon> &nbsp; Custom Pricing</button>
        </div>
        <div class="pb-3 d-flex pl-1 cursor-pointer">
          <button mat-button class="nav-list" routerLinkActive="active-link" [routerLink]="['/dashboard/custom-booking']">
            <mat-icon>book</mat-icon> &nbsp; Manual Booking
          </button>
        </div>
        <div class="pb-3 d-flex pl-1 cursor-pointer">
          <button mat-button class="nav-list" routerLinkActive="active-link" [routerLink]="['/dashboard/enquiries']">
            <mat-icon>contact_support</mat-icon>&nbsp; Enquiries
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>

  <div class="main-page-container">
    <mat-toolbar class="dashboard-toolbar">
     <div class="dashboard-toolbar-c">
       <div class="d-flex align-items-center">
         <button (click)="drawer.toggle()" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
           <mat-icon>menu</mat-icon>
         </button>

         <div class="toolbar-logo-container mr-1">
           <img [src]="sharedService.companyLogoTransperant" alt="Company Logo" class="logo-image">
         </div>
       </div>

       <div class="d-flex full-width justify-content-end align-items-center">
         <div class="cursor-pointer flex-basis-30 d-flex justify-content-end">
           <div [matMenuTriggerFor]="userMenu" class="d-flex justify-content-center align-items-center">
             <div class="user-icon">
               <button  #menuTrigger mat-mini-fab class="mat-button-icon-btn m-r-1"
                       aria-label="Example icon button with a plus one icon">
                 <mat-icon>account_circle</mat-icon>
               </button>
             </div>
             <div class="color-white user-name-box">Hello, <br> <b>{{apiService?.userName | titlecase}}</b></div>
           </div>
           <mat-menu #userMenu="matMenu" >
             <ng-template matMenuContent let-id="id">
               <button (click)="onWebsite()" mat-menu-item>Website
                 <mat-icon color="primary">website</mat-icon>
               </button>
               <button (click)="onChangePassword()" mat-menu-item>Change Password
                 <mat-icon color="primary">lock</mat-icon>
               </button>
               <button mat-menu-item
                       (click)="logout()">Log Out
                 <mat-icon color="primary">logout</mat-icon>
               </button>
             </ng-template>
           </mat-menu>
         </div>
       </div>
     </div>
    </mat-toolbar>

    <router-outlet></router-outlet>

  </div>

</mat-drawer-container>


