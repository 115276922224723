<app-app-header></app-app-header>
<div class="about-us-container">
  <div class="about-us-banner">
    <img src="assets/images/about_us.jpg" alt="">
  </div>
  <div class="about-us-content">
    <div class="left-image">
      <div class="image-tile">
        <img src="assets/images/about_us_1.jpg" alt="">
      </div>
      <div class="image-content">
        <div class="title">
          Our Story
        </div>
        <div class="content">
          Indistays originated from a love for blending the splendor of nature with the comfort of contemporary luxury. Our venture started with a basic concept: to transform camping into an encounter that marvels at the wonders of the wilderness while embracing the finer aspects of life. Our aim was to establish a place where adventure and relaxation seamlessly coexist, making every moment feel like a precious memory.
        </div>
      </div>
    </div>
    <div class="right-image">
      <div class="image-content">
        <div class="title">
          Luxury in Nature
        </div>
        <div class="content">
          At Indistays, we carefully choose amazing camping experiences that go beyond the usual. We pick special places that capture the true beauty and calmness of nature, giving you a break from everyday life. Whether it's the mesmerizing waterfall in Bhandardara, the sunny beaches in Alibaug, or the peaceful shores of Pavna Lake, each spot we choose is like a blank canvas for your one-of-a-kind adventure.
        </div>
      </div>
      <div class="image-tile">
        <img src="assets/images/about_us_2.jpg" alt="">
      </div>
    </div>
    <div class="left-image">
      <div class="image-tile">
        <img src="assets/images/about_us_3.jpg" alt="">
      </div>
      <div class="image-content">
        <div class="title">
          What We Do
        </div>
        <div class="content">
          At Indistays, our dedication lies in discovering unique and less-explored places to ensure our customers experience complete satisfaction and tranquility. Whether nestled in the valleys of Sahyadri, along the shores of Alibaug, or amidst the backwaters of Pawna, we are consistently here to provide excellent service. Additionally, we actively promote local tourism by engaging with the communities, allowing our customers to enjoy the best locations at affordable rates while contributing to the exploration of local culture and attractions.
        </div>
      </div>
    </div>
  </div>
</div>
<!--<svg width="221" height="203" viewBox="0 0 221 203" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--  <circle cx="124.5" cy="106.5" r="96.5" fill="#535453" fill-opacity="0.25"/>-->
<!--  <circle cx="58.7962" cy="58.7938" r="58" transform="rotate(9.51984 58.7962 58.7938)" fill="#565446" fill-opacity="0.2"/>-->
<!--</svg>-->
<app-footer></app-footer>
