<div class="listing-list-container d-flex flex-column gap-5">
  <ng-container *ngFor="let list of listing">
    <div [routerLink]="list?.url" class="single-list p-3">
      <img class="rounded-4 object-fit-cover" [src]="list?.img" alt="">
      <div class="details-container">
        <div class="listing-t-c">
          <div class="title">{{list?.name}}</div>
          <div class="description" [innerHTML]="list?.description">
          </div>
          <div class="d-flex gap-3 amenity-icons">
            <ng-container *ngFor="let amenity of amenities">
              <img class="object-fit-cover" style="width: 30px" [src]="amenity?.icon" alt="">
            </ng-container>
          </div>
        </div>
        <div class="bottom-listing-footer align-items-center d-flex justify-content-between">
          <div *ngIf="list?.startingAt">
            <div class="footer-label">Starting At</div>
            <div>
              <span class="currency">{{list?.startingAt}}</span>
              <span class="n-l">/night</span>
            </div>
          </div>
          <div style="width: 40%">
            <button type="button" class="w-100 indistays-theme-btn">
              Book Now
            </button>
<!--            <button class="booking-btn btn">Book Now</button>-->
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
