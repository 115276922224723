<ng-container *ngIf="showSpinner">
  <div class="item p-3">
    <ngx-skeleton-loader [theme]="{height: '10vh'}" count="1" ></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{height: '40vh'}" count="1" ></ngx-skeleton-loader>
    <div>
      <div style="display: grid; justify-content: center; grid-template-columns: repeat(1, 1fr); column-gap: 10px">
        <ngx-skeleton-loader [theme]="{height: '5vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '5vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader [theme]="{height: '11vh'}" count="1" ></ngx-skeleton-loader>
      <div style="display: grid; justify-content: center; grid-template-columns: repeat(4, 1fr); column-gap: 10px">
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1" ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!showSpinner">
  <app-app-header></app-app-header>
  <div style="background: #3f51b524" class="camping-details">
    <div class="mb-5 camp-header-section d-flex gap-3 flex-column">
      <div class="section-content">
        <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
      </div>
      <div class="camp-name">
        {{campingDetails?.name}}
      </div>
      <div [innerHTML]="campingDetails?.description" class="camp-description">
      </div>
    </div>

    <div *ngIf="amenities && amenities?.length > 0">
      <div class="section-sub-title">
        amenities
      </div>
      <div class="amenities">
        <div *ngFor="let amenity of amenities" class="amenity">
          <img style="max-width: 40px" [src]="amenity?.icon" alt="">
          <div class="amen-name">{{amenity?.name}}</div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="campAccommodations && campAccommodations.length">
      <div class="accommodation-listing-container">
        <div class="accommodation-listing">
          <div class="accommodation-listing-fst d-flex align-items-center">
            <div class="accommodation-list-title">
              availability
            </div>
            <div class="ml-auto custom-date-picker text-center">
              <form [formGroup]="dateRangeForm">
                <mat-form-field color="accent" appearance="outline" class="filter-control full-width example-form-field">
                  <mat-date-range-input [rangePicker]="campaignOnePicker">
                    <input matStartDate placeholder="Start date" formControlName="start_date">
                    <input matEndDate placeholder="End date" formControlName="end_date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div class="single-accommodation">
            <div *ngFor="let acc of campAccommodations" class="single-list p-3 mb-3">
              <div class="single-list-img">
                <img [src]="acc?.img" alt="">
              </div>
              <div class="details-container">
                <div class="details-content-container d-flex justify-content-between">
                  <div class="listing-t-c">
                    <div class="listing-t-c-name">
                      <div class="title">{{acc?.name}}</div>
                      <div class="d-flex align-items-center description">
                        <i class="fa fa-user-circle-o pe-2"></i>
                        <span class="footer-label">X 1</span>
                      </div>
                    </div>
                    <div *ngIf="acc?.amenities && acc?.amenities?.length" class="mt-3 d-flex gap-3 flex-wrap amenity-icons">
                      <ng-container *ngFor="let amenity of acc?.amenities">
                        <img style="max-width: 30px" [src]="amenity?.icon" alt="">
                      </ng-container>
                    </div>
                  </div>
                  <div class="price-container">
                    <div class="price d-flex flex-column">
                      <div>
                        <span class="actual-price pe-2">₹{{acc?.discount_price}}</span>
                        <span class="discount-price">{{acc?.price}}</span>
                      </div>
                      <div class="footer-label night-label">1 night</div>
                    </div>
                    <div class="counter-btn-container">
                    </div>
                  </div>
                </div>
                <div class="bottom-listing-footer">
                  <div>
                    <div class="py-2 bed-label footer-label">{{acc?.beds_available}} Beds Available</div>
                  </div>
                  <div class="counter-btn-container">
                    <ng-container *ngIf="isBookingInCart(acc); else addBed">
                      <div class="counter-container">
                        <div class="button" (click)="onSubtraction(acc)">-</div>
                        <div class="value" id="counterValue">
                          <input (input)="calculate()" style="background: #3f51b514; border: none; text-align: center;" type="number" [(ngModel)]="acc.booking" class="form-control">
                        </div>
                        <div class="button" (click)="onAddition(acc)">+</div>
                      </div>
                    </ng-container>
                    <ng-template #addBed>
                      <div class="add-bed-btn">
                        <button (click)="onAddition(acc)" class="indistays-theme-btn" mat-raised-button>Book Stay</button>
<!--                        <button (click)="toggleModal('confirmBooking')" class="indistays-theme-btn" mat-raised-button>Add Bed</button>-->
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="summary" #summaryElem>
          <div class="accommodation-list-title">
            Summary
          </div>
          <div class="d-flex flex-column gap-3">
            <div class="summary-tile block p-4 bg-white min-w-full">
              <div *ngIf="summaryData && summaryData.length">
                <div *ngFor="let booking of summaryData" class="py-2 summary-row">
                  <div class="pb-3 d-flex justify-content-between py-1 gap-5">
                    <div class="d-flex justify-content-between items-end align-items-center">
                      <div class="text-black mb-0 font-semibold text-lg font-poppins pr-3">
                        {{booking?.name}}
                      </div>
                    </div>
                    <div class="ml-auto d-flex align-items-center">
                      <button (click)="updateCart(booking)" color="warn" class="trash-icon" mat-mini-fab aria-label="Example icon button with a menu icon">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="price-label d-flex justify-content-between font-bold">
                      <div class="font-roboto font-medium">₹</div>
                      {{booking?.discount_price}} x 1 night x {{booking?.booking}}
                    </div>
                    <div class="text-black ml-auto font-mulish text-md font-bold">
                      <span class="text-md font-roboto">₹</span>{{booking?.discount_price * booking?.booking}}
                    </div>
                  </div>
                </div>
                <div class="py-3 summary-row">
                  <div class="d-flex justify-content-between gap-5">
                    <p class="font-semibold text-lg font-poppins">Total</p>
                    <p class="ml-auto font-bold font-roboto">₹<span class="font-mulish">
              {{totalSum}}
            </span></p></div>
                  <div class="d-flex justify-content-between gap-5"><p class="font-semibold text-lg font-poppins">Total Taxes</p>
                    <p class="ml-auto font-roboto font-bold">₹<span class="font-mulish">100</span></p></div>
                </div>
                <div class="py-4 d-flex justify-content-between"><p class="font-poppins">Total Price</p>
                  <p class="ml-auto font-roboto">₹<span class="font-mulish">{{totalSum + 100}}</span></p>
                </div>
                <div class="confirm-booking-btn justify-content-center">
                  <button (click)="toggleModal('makeBooking')" type="button" class="w-100 indistays-theme-btn">
                    Confirm Booking
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center flex-column" *ngIf="summaryData.length === 0">
                <img style="max-width: 160px" src="assets/icons/schedule.png" alt="">
                <div class="pt-4 footer-label">
                  Include accommodations in your booking.
                </div>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center summary-tile rating-box block p-5 bg-white">
              <div class="sub-title">Overall Ratings</div>
              <div class="rate">{{customerReview ? customerReview?.toFixed(1) : 'NA'}}</div>
              <div class="d-flex">
                <star-ratings [rating]="customerReview ? customerReview?.toFixed(0) : 0" [mode]="'view'"></star-ratings>
              </div>
            </div>

            <div class="summary-tile block p-3 bg-white">
              <form [formGroup]="reviewForm">
                <div>
                  <div class="form-group">
                    <textarea formControlName="review" placeholder="Customer Review" class="indi-textarea form-control" id="exampleFormControlTextarea3" rows="7"></textarea>
                  </div>
                  <div class="rating-title">Add Your Ratings</div>
                  <div class="ratings-container d-flex justify-content-center">
                    <star-ratings (ratingUpdated)="updateRating($event)" [rating]="this.reviewForm.get('ratings').value"></star-ratings>
                  </div>
                  <div class="mt-4 d-flex justify-content-center">
                    <button (click)="submitReview()" type="button" class="w-100 indistays-theme-btn">
                      Submit Review
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="location">
      <div class="section-sub-title">
        location
      </div>

      <div [innerHTML]="mapSrc" class="loc-tile">

      </div>

      <div class="pt-3 address-text">
        {{campingDetails?.address}}
      </div>
    </div>


    <div>
      <div class="lg:mt-8 sm:mt-4 md:mt-4 mt-5 lg:mb-6 sm:mb-4 md:mb-4 mb-6">
        <app-section-header [showMore]="false"
                            [mainTitle]="'Accommodations'"
                            [subTitle]="'Top Selling'">
        </app-section-header>
      </div>

      <app-price-ui-cards [cardData]="accommodations"></app-price-ui-cards>
    </div>

    <div>
      <div class="lg:mt-8 sm:mt-4 md:mt-4 mt-5 lg:mb-6 sm:mb-4 md:mb-4 mb-6">
        <app-section-header [showMore]="false"
                            [mainTitle]="'Journey Experiences'"
                            [subTitle]="'testimonial'">
        </app-section-header>
      </div>
      <app-testimonial-cards></app-testimonial-cards>
    </div>
  </div>
  <ng-container *ngIf="summaryData && summaryData.length">
    <div class="justify-content-between w-100 position-fixed payment-amount-drawer d-flex">
      <div class="d-flex flex-column">
        <div class="text-gray-400 font-bold text-xl">Payment Amount</div>
        <div class="py-1 text-gray-900 text-base d-flex gap-2 align-items-center">
          <div>₹ {{totalSum + 100}}</div>
          <div class="text-indigo-400 px-1"><i (click)="goSummary()" class="text-xl fa fa-info-circle"></i></div>
        </div>
      </div>
      <div class="w-5/12 d-flex align-items-center">
        <button (click)="toggleModal('makeBooking')" class="indistays-theme-btn" mat-raised-button>BOOK NOW</button>
      </div>
    </div>
  </ng-container>
  <app-footer></app-footer>
</ng-container>

<div class="modal fade" id="makeBooking" tabindex="-1" role="dialog" aria-labelledby="changePasswordTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content change-pass-content">
      <div class="modal-header border-none">
        <div class="modal-title chng-pass-head" id="exampleModalLongTitle">
          Create Booking
        </div>
        <div (click)="toggleModal('makeBooking')" class="cursor-pointer">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="modal-body d-flex justify-content-center border-none">
        <form [formGroup]="userForm" (ngSubmit)="userAction()">
          <div class="row p-3">
            <div class="col-12 mb-2">
              <mat-label >Enter Your Name</mat-label>
              <mat-form-field appearance="outline" class="full-width">
                <input tabindex="1" matInput formControlName="name" type="text" placeholder="Eg. John Doe">
                <mat-error *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched">Please enter a valid email</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 mb-2">
              <mat-label >Enter Your Email</mat-label>
              <mat-form-field appearance="outline" class="full-width">
                <input tabindex="2" matInput formControlName="email" type="email" placeholder="xxxx@gmail.com">
                <mat-error *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched">Please enter a valid email</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 mb-2">
              <mat-label>Contact Number</mat-label>
              <mat-form-field appearance="outline" class="full-width">
                <input tabindex="3" matInput formControlName="contact_number" type="text" placeholder="9xxxxxxxxx">
                <mat-error *ngIf="userForm.get('contact_number')?.invalid && userForm.get('contact_number')?.touched">Please enter a valid Contact Number</mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- Add more form fields here -->
        </form>
      </div>
      <div class="d-flex justify-content-between mx-1 p-3 modal-footer border-none">
        <button tabindex="4" (click)="toggleModal('makeBooking')"  mat-raised-button>Cancel</button>
        <button tabindex="5" (click)="userAction()" class="color-white" mat-raised-button color="primary">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="confirmBooking" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content change-pass-content">
      <div style="height: 55%" class="modal-body d-flex justify-content-center border-none">
        <div class="w-100 d-flex flex-column">
          <div class="d-flex justify-content-end">
            <button (click)="toggleModal('confirmBooking')" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="d-flex p-2 justify-content-center flex-column align-items-center gap-3">
            <img style="width: 35%" src="assets/icons/success.png" alt="">
            <div class="text-base text-center text-gray-400">We’ve received your enquiry. We’ll revert back to you asap. Please contact for immediate booking.</div>
            <div class="my-3 d-flex flex-column text-center d-flex gap-2">
              <div class="text-gray-600 text-sm">
                Contact Details:
              </div>
              <div class="my-3 d-flex flex-column gap-3">
                <div class="p-3 shadow-lg rounded-2xl d-flex gap-3 align-items-center">
                  <div class="rounded-full w-9 h-9 text-base d-flex align-items-center justify-content-center bg-secondary text-white">
                    <i class="rotate-90 text-base fa fa-phone"></i>
                  </div>
                  <div class="text-gray-800">+91-8788707579</div>
                </div>
                <div class="p-3 shadow-lg rounded-2xl d-flex gap-3 align-items-center">
                  <div class="rounded-full w-9 h-9 text-base d-flex align-items-center justify-content-center bg-secondary text-white">
                    <i class="p-2 fa text-base fa-envelope"></i>
                  </div>
                  <div class="text-gray-800">shubhammandlik@indistays.com</div>
                </div>
              </div>
            </div>

<!--            <div class="mt-2 mb-4">-->
<!--              <button (click)="toggleModal('confirmBooking')" class="indi-style-btn" color="primary" mat-raised-button>-->
<!--                Thanks-->
<!--              </button>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
