<div class="d-flex justify-content-between align-items-center">
  <div class="section-title align-items-start d-flex flex-column justify-content-start">
    <div class="section-category">
      {{subTitle}}
    </div>
    <div class="section-name lg:text-xl md:text-lg sm:text-base">
      {{mainTitle}}
    </div>
  </div>
  <div [routerLink]="showMoreRoute" *ngIf="showMore" class="see-more cursor-pointer">
    see more
  </div>
</div>
