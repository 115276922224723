<div class="dashboard-container p-3">
  <div class="pb-3">
    <mat-card>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center" style="padding: 1rem">
          <div style="font-size: 16px; font-weight: bold">Users</div>
        </div>
        <div class="d-flex align-items-center">
<!--          <div [formGroup]="searchForm" (ngSubmit)="onSearch()" style="width: 200px" class="input-group">-->
<!--            <input placeholder="Search User" formControlName="search" class="form-control py-2 border-right-0 border" type="search" value="search" id="example-search-input">-->
<!--            <span class="input-group-append">-->
<!--              <button class="btn btn-outline-secondary border-left-0 border" type="submit">-->
<!--                    <i class="fa fa-search"></i>-->
<!--              </button>-->
<!--            </span>-->
<!--          </div>-->

          <div class="search-input-box width-300 input-group">
            <input appDebounceKeyUp [debounceTime]="1000"
                   (onEvent)="handleDebouncedKeyUp($event)" type="text" class="form-control" placeholder="Search User">
            <div class="input-group-append">
              <button class="btn" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>

          <mat-paginator
            [pageSize]="pageSize"
            #paginator
            (page)="onPageChange($event)"
            [length]="totalLength">
          </mat-paginator>
          <div style="padding: 0 20px">
            <button (click)="onAddUser()" class="color-white" mat-raised-button color="accent"> Add User &nbsp; <mat-icon>add</mat-icon></button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="pb-3">
    <mat-card>
      <div *ngIf="dataSource && dataSource.length > 0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> User Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> User Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef> User Role </th>
            <td mat-cell *matCellDef="let element"> {{element.role | titlecase}} </td>
          </ng-container>

          <ng-container matColumnDef="action" stickyEnd>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td style="width: 60px" [matMenuTriggerData]="element" [matMenuTriggerFor]="userFeedbackMenu" #menuTrigger mat-cell *matCellDef="let element">
              <div style="cursor: pointer; text-align: center"><mat-icon>more_vert</mat-icon></div>
            </td>
          </ng-container>

          <mat-menu #userFeedbackMenu="matMenu">
            <ng-template matMenuContent let-status="status" let-id="id">
              <button (click)="onEditUser(id)" mat-menu-item>Edit
                <mat-icon color="primary">edit</mat-icon>
              </button>
              <button (click)="onDeleteUser(id)" mat-menu-item>Delete
                <mat-icon color="primary">delete</mat-icon>
              </button>
            </ng-template>
          </mat-menu>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div class="no-data" *ngIf="dataSource.length === 0">
        No Data Available
      </div>
    </mat-card>
  </div>
</div>
