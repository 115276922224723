<div class="footer-container">
  <div class="top-footer d-flex flex-column">
    <div class="main-title">
      Ready for your next <br> adventure?
    </div>
    <div class="sub-title">
      Explore with us, where travel dreams become reality!
    </div>
    <div [routerLink]="'/destinations'" class="cursor-pointer white-btn">
      Explore Now
    </div>
  </div>
  <div class="botton-footer">
    <div class="fst-btm-footer">
      <div class="logo-ctnr">
        <div class="d-flex justify-content-center main-title">
          <img src="assets/company-logo/indistays_white.png" alt="">
        </div>
        <div (click)="sendMessage()" class="d-flex align-items-center gap-2 cursor-pointer green-btn">
          <i style="font-size: 16px" class="fa fa-whatsapp"></i> Send Message
        </div>
      </div>
      <div class="icons-container d-flex gap-3">
        <i class="fa fa-whatsapp" (click)="sendMessage()"></i>
        <i class="fa fa-instagram" (click)="goTo('instagram')"></i>
        <i class="fa fa-facebook" (click)="goTo('facebook')"></i>
      </div>
    </div>
    <div class="footer-menu row">
      <div class="col-lg-4 col-6">
        <div class="menu-container">
          <div class="menu-header">COMPANY</div>
          <div class="menu" [routerLink]="['/home']">Home</div>
          <div class="menu" [routerLink]="['/about-us']">About Us</div>
          <div class="menu" [routerLink]="['/destinations']">Destinations</div>
          <div class="menu" [routerLink]="['/contact-us']">Contact Us</div>
        </div>
      </div>
      <div class="col-lg-4 col-6">
        <div class="menu-container pe-5">
          <div class="menu-header">Reach us</div>
          <div class="menu">+91-8788707579</div>
          <div class="menu">shubhammandlik@indistays.com</div>
        </div>
      </div>
      <div class="col-lg-4 col-12 my-sm-4 col-sm-5 align-items-center d-flex">
        <div class="send-input input-group">
          <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
          <div class="input-group-prepend">
            <button class="btn send-btn" type="button" aria-label="Send Message">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3497 17.9614L11.9543 15.6762L10.5851 16.8242L10.029 14.3803L15.9587 9.18709L8.84882 13.2712L5.5 10.855L18.5 6.96143L15.3497 17.9614Z" fill="#68941C"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin-top: 3rem; color: #fff">

    <div class="copy-right d-flex gap-2">
      <div class="d-flex gap-1 align-items-center"><i class="fa fa-copyright"></i> <div>Indistays {{todayYear}}</div> </div>
      <div>All rights reserved. | Designed by</div>
      <div><a style="color: #1e7e34" href="https://creativehand.co.in">CreativeHand.co.in</a></div>
    </div>
  </div>
</div>
