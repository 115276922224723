<app-app-header></app-app-header>
<div class="contact-us-container">
  <div class="contact-us-title">
<!--    <div class="main-title mb-3">Contact us</div>-->
<!--    <div class="sub-text">-->
<!--      Leave an inquiry or contact us via email and phone. We will contact you within 24 hours during work days-->
<!--    </div>-->
  </div>
  <div class="form-container d-flex">
    <div class="contact-info d-flex justify-content-center flex-column align-items-center">
      <div class="contact-title">Contact Information</div>
      <div class="menu-container">
        <div class="menu mb-1 d-flex justify-content-center align-items-center">
          <img style="max-width: 150px" src="assets/company-logo/indistays_white.png" alt="">
        </div>
        <div class="menu mb-3 d-flex justify-content-center align-items-center">
          <i class="fas fa-phone-alt me-2"></i>
          +91-8788707579
        </div>
      </div>
      <div class="icons-container d-flex gap-3">
        <i class="fa fa-whatsapp" (click)="sendWAMessage()"></i>
        <i class="fa fa-instagram" (click)="goTo('instagram')"></i>
        <i class="fa fa-facebook" (click)="goTo('facebook')"></i>
      </div>
    </div>
    <div class="contact-form">
      <div class="d-flex gap-2 flex-column">
        <div class="form-title">Get in Touch</div>
        <div  class="form-sub-title">
          Need assistance with our products or services? Our customer support team is ready to help. Reach out to us with your questions or concerns.
        </div>
      </div>
      <div class="py-4 full-width">
        <form [formGroup]="contactUsForm">
          <div class="row">
            <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-3">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Name</mat-label>
                <input tabindex="1" #name maxlength="40" matInput formControlName="name" type="text" placeholder="Enter Camping Name">
                <mat-hint align="end">{{name.value.length}} / 40</mat-hint>
                <mat-error *ngIf="contactUsForm.get('name')?.invalid && contactUsForm.get('name')?.touched">Please enter a valid Name</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-3">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Email</mat-label>
                <input tabindex="1" #email maxlength="40" matInput formControlName="email" type="email" placeholder="Enter Email">
                <mat-hint align="end">{{email.value.length}} / 40</mat-hint>
                <mat-error *ngIf="contactUsForm.get('email')?.invalid && contactUsForm.get('email')?.touched">Please enter a valid Email</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-3">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Phone Number</mat-label>
                <input tabindex="1" #phoneNumber maxlength="10" matInput formControlName="contact_number"
                       type="tex" placeholder="Enter Contact Number">
                <mat-hint align="end">{{phoneNumber.value.length}} / 10</mat-hint>
                <mat-error *ngIf="contactUsForm.get('contact_number')?.invalid && contactUsForm.get('contact_number')?.touched">Please enter a valid Contact Number</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-3">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>How did you find us?</mat-label>
                <mat-select formControlName="lead_source">
                  <mat-option *ngFor="let item of findings"
                              [value]="item">{{ item }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-3">
              <button (click)="sendMessage()" class="snd-btn color-white" mat-raised-button color="accent">
                Send Message
              </button>
            </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
