<ng-container *ngIf="showSpinner">
  <div class="item p-3">
    <ngx-skeleton-loader [theme]="{height: '10vh'}" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{height: '40vh'}" count="1"></ngx-skeleton-loader>
    <div>
      <div style="display: grid; justify-content: center; grid-template-columns: repeat(1, 1fr); column-gap: 10px">
        <ngx-skeleton-loader [theme]="{height: '5vh', width: '100%'}" count="1"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '5vh', width: '100%'}" count="1"></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader [theme]="{height: '11vh'}" count="1"></ngx-skeleton-loader>
      <div style="display: grid; justify-content: center; grid-template-columns: repeat(4, 1fr); column-gap: 10px">
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '11vh', width: '100%'}" count="1"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!showSpinner">
  <div style="background: #3f51b524">
    <app-app-header></app-app-header>
    <div class="campings-container">
      <div class="lg:mb-6 sm:mb-4 md:mb-4 mb-6">
        <app-section-header [showMore]="false"
                            [mainTitle]="'Accommodations'"
                            [subTitle]="'Top Selling'">
        </app-section-header>
      </div>
      <app-price-ui-cards [lazyLoaded]="false" [view]="'row'" [cardData]="accommodations"></app-price-ui-cards>
    </div>
    <app-footer></app-footer>
  </div>
</ng-container>
